<template>
  <div
    v-layout="(value: LayoutEnum) => (layout = value)"
    class="vz-calendar"
    :class="{ 'vz-calendar--loading': loading, 'vz-calendar--disabled': disabled }"
  >
    <label v-if="label" class="text-body-2">{{ $t(label) }}</label>

    <div class="vz-calendar__header d-flex justify-space-between align-center ma-2">
      <vz-icon
        clickable
        name="svg:previous"
        size="1.25rem"
        :aria-label="t('COMPONENT_LABELS.BUTTON', { value: 'CALENDAR.PREVIOUS_MONTH' })"
        @click="onBack"
      />

      <div class="d-flex mx-2 gap-2 text-title-1">
        <span>{{ monthDisplay }}</span>
        <span>{{ yearDisplay }}</span>
      </div>

      <vz-icon
        clickable
        role="button"
        name="svg:arrow-right"
        size="1.25rem"
        :aria-label="t('COMPONENT_LABELS.BUTTON', { value: 'CALENDAR.NEXT_MONTH' })"
        @click="onNext"
      />
    </div>

    <div class="vz-calendar__weekdays text-body-2">
      <div v-for="(calendarDay, index) in calendar.weekdays" :key="calendarDay">{{ dayjs().day(index).format('ddd') }}</div>
    </div>

    <div class="vz-calendar__container">
      <vz-popover
        v-for="({ date, day, text, isToday, isDifferentMonth }, index) in calendarMatrix"
        :key="index"
        hold
        :close-timeout="3000"
        :disabled="!actions?.length || isDifferentMonth"
        @click="onSelect(date)"
        @dblclick="actions?.[0].click(date.valueOf())"
      >
        <template #activator>
          <vz-calendar-day
            :class="{
              'vz-calendar__day': !!date,
              'vz-calendar__day--outline': dayBorder,
              'vz-calendar__day--today': isToday,
              'vz-calendar__day--active': today === date.valueOf(),
              // 'vz-calendar__day--mark': !!events[text]?.length,
              'vz-calendar__day--disabled':
                disabled || (date && minDate && date.valueOf() < minDate) || (date && maxDate && date.valueOf() > maxDate),
              'c-mono-400': isDifferentMonth,
            }"
            role="button"
            :aria-label="t('COMPONENT_LABELS.BUTTON', { value: 'CALENDAR.SELECT_DAY' })"
            :extendable="extendable"
            :day="day || 0"
          >
            <template v-if="showOnlyIndicationForEvent">
              <div
                v-for="(event, eventIndex) in getEventMap(text)"
                :key="eventIndex"
                v-tooltip="{ isAlwaysOn: true, text: event.tooltip }"
                class="vz-calendar__day-mark"
                :style="{
                  color: event.color,
                  width: event.eventPercentage + '%',
                  marginInlineStart: event.startPercentage + '%',
                  minWidth: Math.max(event.eventPercentage || 5, 5) + '%',
                }"
              >
                {{ showOnlyIndicationForEvent ? '-' : event.title }}
              </div>
            </template>

            <template v-else>
              <template v-for="({ title, fromTime, toTime, color }, eventIndex) in events[text]" :key="eventIndex">
                <vz-badge
                  v-tooltip="{ text: `${[fromTime, toTime].join(' - ')} ${title}` }"
                  class="font-size-12"
                  :prefix="fromTime"
                  :text="title"
                  :color="color"
                />
              </template>
            </template>
          </vz-calendar-day>
        </template>

        <div class="text-title-1">{{ formattedDate(date.valueOf()) }}</div>

        <vz-divider class="my-1" />

        <div
          v-for="({ text: actionText, icon, click: onLinkClick }, actionIndex) in actions"
          :key="actionIndex"
          class="d-flex align-center flex-wrap gap-2 my-1 link text-body-2"
          @click="onLinkClick(date.valueOf())"
        >
          <vz-icon v-if="icon" :name="icon?.name" :size="icon?.size || '1.25rem'" />

          <span>{{ $t(actionText) }}</span>
        </div>
      </vz-popover>
    </div>

    <div :class="['vz-calendar__error', { 'vz-calendar__error--hidden': hideDetails }]">
      <p v-if="validateMessage" :class="{ 'vz-datepicker__error-internal': !isTouched }">{{ $t(validateMessage) }}</p>

      <p v-else-if="errorMessage">{{ $t(errorMessage) }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import { computed, type PropType, ref, watch } from 'vue';
import Calendar from '@/shared/services/calendar-service/calendar.service';
import VzCalendarDay from '@/shared/components/calendar/components/vz-calendar-day.vue';
import { useTranslator } from '@/plugins/i18n/helpers';
import dayjs from 'dayjs';
import { useValidator } from '@shared/components/fields/helpers';
import { CalendarEvent } from '@/views/calendar/types';
import VzPopover from '@shared/components/menus/vz-popover.vue';
import { MenuItem } from '@shared/components/menus/models/menu-item';
import { formattedDate } from '@/views/calendar/helpers';
import { LayoutEnum } from '@shared/directives/layout.enum';

const t = useTranslator();

const props = defineProps({
  name: { type: String as PropType<string | undefined>, default: undefined },
  viewDate: { type: Object as PropType<Date | undefined | null>, default: undefined },
  modelValue: { type: Object as PropType<Date | undefined | null>, required: true },
  label: { type: String, default: '' },
  minDate: { type: Number, default: undefined }, // timestamp
  maxDate: { type: Number, default: undefined }, // timestamp
  events: { type: Object as PropType<Record<string, Array<CalendarEvent>>>, default: () => ({}) }, // events
  idKey: { type: String, default: '_id' }, // event id key name
  titleKey: { type: String, default: 'title' }, // event title key name
  dateFromKey: { type: String, default: 'dateFrom' }, // event start date key name
  dateToKey: { type: String, default: 'dateTo' }, // event end date key name
  loading: { type: Boolean, default: false },
  extendable: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  hideDetails: { type: Boolean, default: false },
  rules: { type: Object as PropType<ValidatorFieldRules>, default: () => ({}) },
  errorMessage: { type: String as PropType<string | null>, default: null },
  dayBorder: { type: Boolean, default: true },
  dayActions: { type: Array as PropType<Array<MenuItem> | undefined>, default: undefined },
  dotMark: { type: Boolean, default: false },
});

const emit = defineEmits(['update:events', 'update:view', 'update:model-value']);
const localDate = ref<number>((props.modelValue ? new Date(props.modelValue) : new Date()).valueOf());
const layout = ref<LayoutEnum>(LayoutEnum.xl);

const showOnlyIndicationForEvent = computed(() => [LayoutEnum.xs, LayoutEnum.sm, LayoutEnum.md].includes(layout.value) || props.dotMark);

const calendar = new Calendar();

const actions = computed(() => props.dayActions?.filter(({ hidden }) => !hidden) || []);

const vModel = computed({
  get: (): Date => {
    if (props.modelValue) {
      return new Date(props.modelValue);
    } else if (props.minDate) {
      return new Date(props.minDate);
    }

    return new Date();
  },
  set: (value) => {
    localDate.value = value.valueOf();

    emit('update:model-value', value.valueOf());
  },
});

const today = computed(() => new Date(vModel.value.valueOf()).setHours(0, 0, 0, 0).valueOf());

const monthDisplay = computed(() => dayjs().month(new Date(localDate.value).getMonth()).format('MMMM'));
const yearDisplay = computed(() => dayjs().year(new Date(localDate.value).getFullYear()).format('YYYY'));
const currentValue = computed(() => localDate.value.valueOf());
const currentYear = computed(() => new Date(localDate.value).getFullYear());
const currentMonth = computed(() => new Date(localDate.value).getMonth());
const calendarMatrix = computed(() => calendar.matrix(currentYear.value, currentMonth.value));
const currentDayEvents = computed(() => props.events[dayjs(currentValue.value).format('YYYY-MM-DD')] || []);

const getEventMap = (date: string): Array<CalendarEvent> => {
  return (
    props.events[date]?.map((event) => ({
      ...event,
      tooltip: `${event.fromTime} - ${event.toTime} ${event.title}`,
    })) || []
  );
};

const onSelect = (date: Date) => {
  if (!date || props.disabled) {
    return;
  }

  vModel.value = date;
};

const onNext = () => {
  if (props.disabled) {
    return;
  }

  const date = new Date(localDate.value);
  const month = date.getMonth();
  const year = date.getFullYear();

  if (month === 11) {
    date.setFullYear(year + 1);
    date.setMonth(0);
  } else {
    date.setMonth(month + 1);
  }

  localDate.value = date.valueOf();
  emit('update:view', date);
};

const onBack = () => {
  if (props.disabled) {
    return;
  }

  const date = new Date(localDate.value);
  const month = date.getMonth();
  const year = date.getFullYear();

  if (month === 0) {
    date.setFullYear(year - 1);
    date.setMonth(11);
  } else {
    date.setMonth(month - 1);
  }

  localDate.value = date.valueOf();
  emit('update:view', date);
};

watch(
  () => [currentDayEvents.value],
  () => emit('update:events', currentDayEvents.value),
  { immediate: true }
);

const { validateMessage, isTouched } = useValidator(
  computed(() => vModel.value.valueOf()),
  computed(() => props.rules),
  props.name || props.label
);
</script>

<style lang="scss">
.vz-calendar {
  --border-color: var(--color-primary-200);
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    user-select: none;

    svg {
      @include rtl(transform, scale(-1));
    }

    @include min-md-layout {
      svg {
        height: 24px;
        width: 24px;
      }

      > div > span {
        font-size: 24px;
      }
    }

    @include max-md-layout {
      svg {
        height: 14px;
        width: 14px;
      }

      > div > span {
        font-size: 18px;
      }
    }
  }

  &__weekdays {
    position: relative;
    display: flex;
    font-weight: var(--font-weight-medium);
    margin: 0.5rem 0;

    .vz-calendar--loading & {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.25rem;
        background-image: linear-gradient(100deg, transparent 5%, var(--color-primary-900) 42.5%, transparent 95%);
        background-repeat: no-repeat;
        background-size: 35% 100%;
        background-position: 0 0;
        animation: skeletonOverlay 2s linear infinite;
      }

      @keyframes skeletonOverlay {
        0% {
          background-position: -100% 0;
        }
        100% {
          background-position: 200% 0;
        }
      }
    }

    > * {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(7, calc(100% / 7));
    grid-template-rows: repeat(6, calc(100% / 6));
    flex-grow: 1;
    gap: 0;

    .vz-popover__activator {
      width: 100%;
      height: 100%;

      .vz-calendar-day {
        width: 100%;
        height: 100%;
      }
    }

    > * {
      border-inline-start: 1px solid var(--border-color);
      border-block-start: 1px solid var(--border-color);
      border-radius: 0 !important;

      &:nth-child(7n) {
        border-inline-end: 1px solid var(--border-color);
      }

      &:nth-last-child(-n + 7) {
        border-block-end: 1px solid var(--border-color);
      }
    }
  }

  &__day {
    position: relative;
    transition: background-color 0.3s;
    overflow: hidden;
    user-select: none;

    > div:first-child {
      font-weight: var(--font-weight-medium);
    }

    &--mark:has(.vz-calendar__day-mark) {
      color: var(--color-primary-400) !important;
    }

    &-mark {
      max-height: 0.25rem;
      border-radius: 0.25rem;
      max-width: 1.5rem;
      background-color: currentColor;
    }

    @include min-sm-layout {
      border-radius: var(--border-radius-regular);
    }

    &-events {
      @include max-md-layout {
        display: none;
      }
    }

    &--today {
      color: var(--color-primary-900);

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-color: currentColor;
        opacity: 0.1;
      }
    }

    &--active {
      background-color: var(--color-background-regular);
      outline: var(--outline-focus);
    }

    &--disabled {
      color: var(--color-disabled);
    }

    > * {
      margin: 2px;
    }
  }

  &--disabled {
    color: var(--color-disabled);

    .vz-calendar__container,
    .vz-calendar__day--disabled {
      cursor: initial !important;
      background-color: var(--color-background-disabled);
    }

    .vz-calendar__header > * {
      cursor: initial !important;
    }
  }
}
</style>
