import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-errors"]
const _hoisted_2 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_3 = { class: "row-flex-4" }
const _hoisted_4 = { key: 1 }

import type VzCanvasBoardCropper from '@shared/components/fields/vz-canvas-board-cropper.vue';
import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import type { ErrorResponse } from '@shared/services/api-service/models';
import { computed, type PropType, ref } from 'vue';
import { useValidator } from '@shared/components/fields/helpers';
import { useTranslator } from '@/plugins/i18n/helpers';
import { CanvasBoardCropperRef } from '@shared/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-multi-canvas-board-cropper',
  props: {
  modelValue: { type: [String, Array] as PropType<string | Array<string> | undefined>, required: true },
  multiple: { type: [Boolean, Number], default: false },
  name: { type: String as PropType<string | undefined>, default: undefined },
  label: { type: String as PropType<string | undefined>, default: undefined },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  hideDetails: { type: Boolean, default: false },
  errorMessage: { type: [Object, String] as PropType<ErrorResponse | string | null | undefined>, default: null },
},
  emits: ['update:model-value'],
  setup(__props, { expose: __expose, emit: __emit }) {

const t = useTranslator();

const props = __props;

const emit = __emit;
const vzCanvasBoardCropperRefs = ref<Array<CanvasBoardCropperRef>>([]);

const vModel = computed({
  get: (): string => (Array.isArray(props.modelValue) ? props.modelValue[0] : props.modelValue || ''),
  set: (value: string) => {
    emit('update:model-value', value);
  },
});

const vMultipleModel = computed({
  get: (): Array<string> => {
    const currentValue = [...(Array.isArray(props.modelValue) ? props.modelValue : []).filter((value) => !!value)];

    return typeof props.multiple === 'boolean' || currentValue.length < props.multiple ? [...currentValue, ''] : currentValue;
  },
  set: (value: Array<string>) => {
    emit('update:model-value', value?.filter((value) => !!value));
  },
});

const { validateMessage, isTouched } = useValidator(
  computed(() => (props.multiple ? vMultipleModel.value : vModel.value)),
  computed(() => props.rules),
  props.name || props.label || 'COMPONENT_LABELS.IMAGE_CROPPER'
);

const externalError = computed(() => {
  if (!props.errorMessage) {
    return;
  }

  if (typeof props.errorMessage === 'string') {
    return props.errorMessage;
  }

  const { message, ...fields } = props.errorMessage.errorMessage!.pop() || {};

  return message ? t(message, { ...fields, ...(props.label ? { property: props.label } : {}) }) : undefined;
});

const onUpdateMultipleModel = (index: number, value?: string): void => {
  vMultipleModel.value = vMultipleModel.value.map((item, i) => (i === index ? value || '' : item));
};

const saveAll = (): void => {
  vzCanvasBoardCropperRefs.value.forEach((ref) => ref?.save());
};

__expose({ save: saveAll });

return (_ctx: any,_cache: any) => {
  const _component_vz_canvas_board_cropper = _resolveComponent("vz-canvas-board-cropper")!

  return (!__props.multiple)
    ? (_openBlock(), _createBlock(_component_vz_canvas_board_cropper, _mergeProps({
        key: 0,
        ref: (el) => (vzCanvasBoardCropperRefs.value[0] = el),
        modelValue: vModel.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((vModel).value = $event)),
        rules: __props.rules,
        label: __props.label,
        name: __props.name,
        "hide-details": __props.hideDetails,
        "error-message": __props.errorMessage
      }, _ctx.$attrs), null, 16, ["modelValue", "rules", "label", "name", "hide-details", "error-message"]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        "data-errors": _unref(validateMessage)
      }, [
        (__props.label)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t(__props.label)), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(vMultipleModel.value, (_, index) => {
            return (_openBlock(), _createBlock(_component_vz_canvas_board_cropper, _mergeProps({
              key: index,
              ref_for: true,
              ref: (el) => (vzCanvasBoardCropperRefs.value[index] = el),
              modelValue: vMultipleModel.value[index],
              "onUpdate:modelValue": ($event: any) => ((vMultipleModel.value[index]) = $event)
            }, _ctx.$attrs, {
              "onUpdate:modelValue": ($event: any) => (onUpdateMultipleModel(index, $event))
            }), null, 16, ["modelValue", "onUpdate:modelValue"]))
          }), 128))
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(['vz-input__error', { 'vz-input__error--hidden': __props.hideDetails && !_unref(validateMessage) && !externalError.value }]),
          role: "alert"
        }, [
          (_unref(validateMessage))
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: _normalizeClass({ 'vz-input__error-internal': !_unref(isTouched) })
              }, _toDisplayString(_ctx.$t(_unref(validateMessage))), 3))
            : (externalError.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t(externalError.value)), 1))
              : _createCommentVNode("", true)
        ], 2)
      ], 8, _hoisted_1))
}
}

})